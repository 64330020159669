function DropdownMenu (props) {
    return (
        <div>
            <button className={props.className ? 'btn dropdown-toggle '+props.className : 'btn dropdown-toggle'} data-bs-toggle='dropdown'><i className='fa-xl fa-solid fa-bars' /></button>
            <div className='dropdown-menu theme-secondary border-0 p-0 m-0'>
                <div className='d-grid shadow'>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

const Item = (props) => {
    return (
        <button className='btn btn-lg btn-secondary border-3 border-top-0 border-end-0 shadow-none px-2 my-0 text-start' onClick={props.action}><i class={props.icon} />{props.label}</button>
    );
}
DropdownMenu.Item = Item;

export default DropdownMenu;