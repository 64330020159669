import { useState, useEffect, useCallback } from 'react';
import PointsToaster from '../../PointsToaster';
import ScoreBoard from '../../components/scoreboard/ScoreBoard';
import { useLocalStorage } from '../../components/hooks/useLocalStorage';
import { useEventListener } from '../../components/hooks/useEventListener';
import 'bootstrap/dist/css/bootstrap.css';

import alphabet from './alphabet.json';
import spelling_words from './spelling.json';

function Spelling() {
  const [index, setIndex] = useState(getRandomInt(spelling_words.length));
  const [spelling_word, setSpellingWord] = useState('');
  const [letters, setLetters] = useState([]);
  const [choices, setChoices] = useState([]);
  const [hide_index, setHideIndex] = useState(0);
  const [picked, setPicked] = useState([]);

  const [toasts, setToasts] = useState([]);

  const [high_score, setHighScore] = useLocalStorage('high_score', 0);
  const [score, setScore] = useState(0);

  const [keyPressed, setKeyPressed] = useState('');

  const kbHandler = useCallback(
    ({ key }) => {
      console.log('Spelling.js keypress');
      setKeyPressed(key.toLowerCase());
    },
    [setKeyPressed]
  );
  // Add event listener using our hook
  useEventListener("keypress", kbHandler);

  useEffect(() => {
    console.log('Spelling.js useEffect() index invoked');
    console.log('Spelling.js useEffect() index: '+index);
    console.log('Spelling.js useEffect() index spelling_words.length: '+spelling_words.length);

    console.log('Spelling.js useEffect() index setSpellingWord()');
    setSpellingWord(spelling_words[index]);
  }, [index]);

  useEffect(() => {
    console.log('Spelling.js useEffect() spelling_word invoked');
    console.log('Spelling.js useEffect() spelling_word: '+spelling_word);

    console.log('Spelling.js useEffect() spelling_word setLetters()');
    setLetters([...spelling_word]);
  }, [spelling_word]);

  useEffect(() => {
    console.log('Spelling.js useEffect() letters invoked');
    console.log('Spelling.js useEffect() letters spelling_word: '+JSON.stringify(spelling_word));

    console.log('Spelling.js useEffect() letters setHideIndex()');
    setHideIndex(getRandomInt(letters.length));
  }, [letters]);

  useEffect(() => {
    if(score > high_score){
      console.log('Spelling.js useEffect() score new high score');
      setHighScore(score);
    }
  }, [score]);

  useEffect(() => {
    console.log('Spelling.js useEffect() letters,hide_index invoked');
    if((letters.length > 0) && (hide_index >= 0)){
      console.log('Spelling.js useEffect() letters,hide_index setChoices()');
      let tmpChoices = [
        letters[hide_index]
      ];
      while(tmpChoices.length < 6){
        let tmpChoice = alphabet[getRandomInt(alphabet.length)];
        if(!tmpChoices.includes(tmpChoice)){
          tmpChoices.push(tmpChoice);
        }
      }
      tmpChoices.shift();
      tmpChoices[getRandomInt(tmpChoices.length)] = letters[hide_index];
      setChoices(tmpChoices);
    }
  }, [letters, hide_index]);

  useEffect(() => {
    console.log('Spelling.js useEffect() toasts invoked');
    const interval = setInterval(() => {
      closeToast();
    }, 200);
    return () => {
      clearInterval(interval);
    };
  }, [toasts]);

  useEffect(() => {
    if(choices.includes(keyPressed)){
      submitAnswer(keyPressed);
    }
  }, [keyPressed, choices])

  const submitAnswer = (answer) => {
    console.log('Spelling.js submitAnswer() invoked');
    console.log('Spelling.js submitAnswer() answer: '+answer);

    const word = document.getElementById('word');
    if(answer == letters[hide_index]){
      console.log('Spelling.js submitAnswer() answer is correct');
      setPicked([]);
      setKeyPressed('');
      addToast('success','+10');
      setScore(score+10);
      setHideIndex(-1);
      word.classList.add('success');
      word.classList.remove('error');

      setTimeout(function(){
        const word = document.getElementById('word');
        word.classList.remove('success');

        let new_index = getRandomInt(spelling_words.length);
        while(new_index == index){
          new_index = getRandomInt(spelling_words.length);
        }
        setIndex(new_index);
      }, 1000);
    }
    else{
      console.log('Spelling.js submitAnswer() answer is wrong');
      setPicked(picked.concat(answer));
      addToast('danger','-5');
      setScore(score-5);    
      word.classList.add('error');
      word.classList.remove('success');
 
      setTimeout(function(){
        const word = document.getElementById('word');
        word.classList.remove('error');
      }, 1000);        
    }
  };

  const addToast = (type,text) => {
    console.log('Spelling.js addToast() invoked');
    setToasts([...toasts,{time: Date.now(),className:'theme-'+type,text:text}]);
  }

  const closeToast = () => {
    console.log('Spelling.js closeToast() invoked');
    console.log('Spelling.js closeToast() toasts: '+JSON.stringify(toasts));

    let expired = Date.now()-1000;
    console.log('Spelling.js closeToast() expired: '+expired);

    let new_toasts = toasts.filter((record) => {
      return record.time>expired;
    });

    console.log('Spelling.js closeToast() new_toasts return:'+JSON.stringify(new_toasts));
    setToasts(new_toasts);
  }

  const playAudio = () => {
    console.log('Spelling.js playAudio() invoked');
    var synth = window.speechSynthesis;
    var utterThis = new SpeechSynthesisUtterance(spelling_word);
    var voices = synth.getVoices();
    console.log('Spelling.js playAudio() voices: '+JSON.stringify(voices));
    utterThis.voice = voices[1];
    synth.speak(utterThis);  
  }

  console.log('Spelling.js index: '+index);
  console.log('Spelling.js spelling_word: '+spelling_word);
  console.log('Spelling.js letters: '+JSON.stringify(letters));
  console.log('Spelling.js choices: '+JSON.stringify(choices));
  console.log('Spelling.js hide_index: '+hide_index);

  return (
    <div className='d-flex flex-column justify-content-center'>
      <div className='d-flex flex-row'>
        <div className='w-25 justify-content-start ms-3'>
          <ScoreBoard>
            <ScoreBoard.Header className='p-1 border border-primary border-3 border-bottom-0 rounded-top-3vmin fs-6 fw-bold'>HIGH SCORE</ScoreBoard.Header>
            <ScoreBoard.Body className='d-flex justify-content-end me-2 pe-1 border border-primary border-3 border-top-0 rounded-bottom-3vmin fs-1 fw-bold'>{high_score}</ScoreBoard.Body>
          </ScoreBoard>     
        </div>
        <div className='flex-fill justify-content-center'><PointsToaster toasts={toasts} /></div>
        <div className='w-25 justify-content-end me-3'>
          <ScoreBoard>
            <ScoreBoard.Header className='border border-primary border-3 border-bottom-0 rounded-top-3vmin fs-6 fw-bold'>SCORE</ScoreBoard.Header>
            <ScoreBoard.Body className='d-flex justify-content-end me-2 pe-1 border border-primary border-3 border-top-0 rounded-bottom-3vmin fs-1 fw-bold'>{score}</ScoreBoard.Body>
          </ScoreBoard>
        </div>
      </div>
      <div id='word' className='mx-auto my-3 px-3 py-1 d-flex flex-row flex-nowrap justify-content-center rounded-pill'>
        {
          letters.map((letter,index) => {
            return (<div className='word-block theme-light m-1 m-md-3 d-flex border border-4 align-items-center justify-content-center fs-1 fw-bold'>{index != hide_index ? letter.toUpperCase() : null}</div>);
          })
        }
      </div>
      <div className='mb-3 d-flex justify-content-center'><button class="playbtn d-flex align-self-center btn btn-primary border-5 shadow-none p-3" onClick={playAudio}><i class="d-flex align-self-center fa-solid fa-2xl fa-volume-high"></i><span className='fs-6 fw-bold ms-3'>PLAY</span></button></div>
      <div className="mt-3 mx-auto d-flex flex-wrap justify-content-center" style={{maxWidth:'768px'}}>
        {
          choices.map((choice,index) => {
            let isPicked = picked.includes(choice);
            return (<button className={isPicked ? 'letter-block m-3 btn btn-light border-5 shadow-none fs-1 fw-bold disabled' : 'letter-block m-3 btn btn-light border-5 shadow-none fs-1 fw-bold'} onClick={() => submitAnswer(choice)}>{choice.toUpperCase()}</button>);
          })
        }
      </div>    
    </div>
  );
}

function getRandomInt(max){
  return Math.floor(Math.random()*max);
}

export default Spelling;
