import { useCallback, useState, useEffect } from 'react';
import { useHttpPostJSON } from '../components/hooks/useHttpPostJSON';

function Test() {
    const [value1] = useHttpPostJSON('/test.php');


    const [value2] = useHttpPostJSON('/test.php', {action: 'test1', name: 'Test User'});


    const [value3, refreshValue3] = useHttpPostJSON('/test.php', {
        action: 'test2'
    });


    const [value4, refreshValue4] = useHttpPostJSON('/test.php',
    {
        action: 'test2',
        min: 100,
        max: 200
    }, () => function (jsonObj) {
        console.log('value4Parse invoked');
        console.log('value4Parse jsonObj: '+JSON.stringify(jsonObj));
        if(jsonObj !== undefined && jsonObj !== null){
            if(jsonObj.data) { 
                console.log('value4Parse return jsonObj.data: '+jsonObj.data);
                return ["success", jsonObj.data];
            }
        }

        console.log('value4Parse error');
        return ["error", null];
    });


    const [value5, refreshValue5] = useHttpPostJSON('/test.php', null, null, false);


    const [value6, refreshValue6, value6Status, value6Error, value6SetUrl, value6SetData] = useHttpPostJSON('/test.php', null, null, false);

    const updateValue6 = () => {
        value6SetData({
            action: 'test2'
        });
        refreshValue6();
    }


    const [value7, refreshValue7, value7Status, value7Error, value7SetUrl, value7SetData] = useHttpPostJSON();

    const updateValue7 = () => {
        value7SetUrl('/test.php');
        value7SetData({
            action: 'test2',
            min: 10,
            max: 20
        });
        refreshValue7();
    }


    const [value8, refreshValue8, value8Status, value8Error, value8SetUrl, value8SetData] = useHttpPostJSON('/test.php',null,null,false);

    const updateValue8 = () => {
        value8SetData({
            action: 'test2',
            min: 10,
            max: 20
        });
        refreshValue8();
    }


    const [value9, refreshValue9, value9Status, value9Error, value9SetUrl, value9SetData] = useHttpPostJSON('/test.php',null,
        () => function (jsonObj) {
            console.log('value9Parse invoked');
            console.log('value9Parse jsonObj: '+JSON.stringify(jsonObj));
            if(jsonObj !== undefined && jsonObj !== null){
                if(jsonObj.data) { 
                    console.log('value9Parse return jsonObj.data: '+jsonObj.data);
                    return ["success", jsonObj.data];
                }
            }

            console.log('value9Parse error');
            return ["error", null];
        },
    false);

    const updateValue9 = () => {
        value9SetData({
            action: 'test2',
            min: 10,
            max: 20
        });
        refreshValue9();
    }


    const [value10, refreshValue10, value10Status, value10Error, value10SetUrl, value10SetData, value10SetCallback] = useHttpPostJSON('/test.php',null,null,false);

    const updateValue10 = () => {
        value10SetData({
            action: 'test2',
            min: 10,
            max: 20
        });
        value10SetCallback(() => function (jsonObj) {
            console.log('value10Parse invoked');
            console.log('value10Parse jsonObj: '+JSON.stringify(jsonObj));
            if(jsonObj !== undefined && jsonObj !== null){
                if(jsonObj.data) { 
                    console.log('value10Parse return jsonObj.data: '+jsonObj.data);
                    return ["success", jsonObj.data];
                }
            }

            console.log('value10Parse error');
            return ["error", null];
        });
        refreshValue10();
    }


    return (
        <>
        <div>value1: {JSON.stringify(value1)}</div>
        <div>value2: {JSON.stringify(value2)}</div>
        <div>value3: {JSON.stringify(value3)}<button onClick={refreshValue3}>refresh</button></div>
        <div>value4: {JSON.stringify(value4)}<button onClick={refreshValue4}>refresh</button></div>
        <div>value5: {JSON.stringify(value5)}<button onClick={refreshValue5}>refresh</button></div>
        <div>value6: {JSON.stringify(value6)}<button onClick={updateValue6}>refresh</button></div>
        <div>value7: {JSON.stringify(value7)}<button onClick={updateValue7}>refresh</button></div>
        <div>value8: {JSON.stringify(value8)}<button onClick={updateValue8}>refresh</button></div>
        <div>value9: {JSON.stringify(value9)}<button onClick={updateValue9}>refresh</button></div>
        <div>value10: {JSON.stringify(value10)}<button onClick={updateValue10}>refresh</button></div>
        </>
    );
}

export default Test;