import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import '@fortawesome/fontawesome-free/css/all.css';
import './themes.css';

function ThemeSelector(props){
    if(!props.show){ return null; }

    const changeTheme = (theme) => {
        console.log('ThemeSelector.js changeTheme() theme: '+theme);
        props.setTheme(theme);
    }

    const themes = require('./themes.json');

    const hide = () => {
        console.log('ThemeSelector.js hide invoked');
        props.setShow(false);
    }

    return (
        <Modal show={props.show} onHide={hide} centered>
            <Modal.Header className="m-0 ps-2 pe-1 py-0 theme-primary">
                <Modal.Title>CHOOSE THEME</Modal.Title>
                <button className='mx-0 my-1 btn btn-primary border-2' onClick={hide}><i className='fa-solid fa-xl fa-xmark' aria-hidden="true"></i></button>
            </Modal.Header>
            <Modal.Body className="m-0 p-2 bg-body">
                <div className='d-flex flex-wrap justify-content-center'>
                {themes.map((choice) => <button key={choice.theme} class="btn-theme-choice m-3 d-flex align-items-center justify-content-center border-5 rounded-circle fs-6 fw-bold" onClick={() => changeTheme(choice.theme)} style={{backgroundColor: choice.primary, borderStyle: 'solid', borderColor: choice.body, color: choice.primary_text}}>{choice.label}</button>)}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ThemeSelector;