import { useState, useEffect } from 'react';

import {
  Routes,
  Route,
  useNavigate, 
  useLocation
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import './react-transition-group.css';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

import { useLocalStorage } from './components/hooks/useLocalStorage';

import Menu from './components/menu/Menu';

import ThemeSelector from './components/themes/ThemeSelector';
import Theme from './components/themes/Theme';

import Test from './test/Test';
import MainMenu from './MainMenu';
import Spelling from './games/spelling/Spelling';
import Math from './games/math/Math';

import './style.css';

function App() {
  const navigate = useNavigate();
	const location = useLocation();

  const [theme, setTheme] = useLocalStorage("theme","blue");
  const [showThemeSelector, setShowThemeSelector] = useState(false);

  return (
    <>
      <Theme theme={theme} />
      <div className='d-flex flex-row theme-primary border-bottom border-5 mb-2'>
        <div className='d-flex flex-fill justify-content-start align-self-center px-3 fs-3 fw-bold'>Flash Cards</div>
        <div className='d-flex justify-content-end align-self-center'>
          <Menu className='btn-primary border-0 shadow-none p-1'>
            <Menu.Item icon='fa-solid fa-xl fa-house my-3 ms-0 me-2' label='Main Menu' action={() => navigate('/')} />
            <Menu.Item icon='fa-solid fa-xl fa-palette my-3 ms-0 me-2' label='Change Theme' action={() => setShowThemeSelector(true)} />
            <Menu.Item icon='fa-solid fa-xl fa-microphone my-3 ms-0 me-2' label='Voice Settings' action={() => setShowThemeSelector(true)} />
          </Menu>
        </div>
      </div>

      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Routes>
                  <Route path="/test" element={
                    <Test>
                      <h1>TEST SHIT</h1>
                    </Test>
                  } />
                  <Route path="/spelling" element={<Spelling />} />
                  <Route path="/math" element={<Math />} />
                  <Route path="/" element={<MainMenu />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>

      <ThemeSelector show={showThemeSelector} setShow={setShowThemeSelector} setTheme={setTheme} />
    </>
  );
}

export default App;
