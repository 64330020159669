function ScoreBoard(props){
    return (
        <div className={'scoreboard '+props.className}>
            {props.children}
        </div>
    );
}

const Header = (props) => <div className={'scoreboard-header w-auto mw-100 overflow-hidden '+props.className}><span className='d-block w-100 text-nowrap text-truncate'>{props.children}</span></div>
ScoreBoard.Header = Header;

const Body = (props) => <div className={'scoreboard-body w-100 '+props.className}>{props.children}</div>
ScoreBoard.Body = Body;

export default ScoreBoard;