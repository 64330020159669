import { useNavigate } from "react-router-dom";

function MainMenu () {
	const navigate = useNavigate();

    return (
        <div className="mx-auto d-flex flex-column justify-content-center" style={{maxWidth:'768px'}}>
            <button className='mt-3 btn btn-lg btn-primary border-3 rounded-3vmin p-3 d-flex align-items-center justify-content-start' onClick={() => navigate('/spelling')}><i class="w-25 fa-solid fa-2xl fa-school"></i><span className='w-75 text-start fs-1 fw-bold'>Spelling</span></button>
            <button className='mt-3 btn btn-lg btn-primary border-3 rounded-3vmin p-3 d-flex align-items-center justify-content-start' onClick={() => navigate('/math')}><i class="w-25 fa-solid fa-2xl fa-calculator"></i><span className='w-75 text-start fs-1 fw-bold'>Math</span></button>
        </div>
    );
}

export default MainMenu;