import { useState, useEffect, useCallback } from 'react';

export const useHttpPostJSON = (initialUrl = null, initialData = null, initialCallback = null, immediate = true) => {
    console.log('useHttpPostJSON.js invoked');
    console.log('useHttpPostJSON.js initialUrl: '+initialUrl);
    console.log('useHttpPostJSON.js initialData: '+JSON.stringify(initialData));

    const [url, setUrl] = useState(initialUrl);
    const [data, setData] = useState(initialData);
    const [callback, setCallback] = useState(initialCallback);
    const [readyToUpdate, setReadyToUpdate] = useState(immediate);
    const [status, setStatus] = useState("idle");
    const [value, setValue] = useState(null);
    const [error, setError] = useState(null);

    const execute = useCallback(() => {
        console.log('useHttpPostJSON.js useCallback() execute() invoked');
        console.log('useHttpPostJSON.js useCallback() url: '+url);

        if(!url){
            setStatus("error")
            setError("URL not set");
        }

        console.log('useHttpPostJSON.js useCallback() setStatus pending');
        setStatus("pending");
        setValue(null);
        setError(null);

		let formData = new FormData();
        if(data !== undefined && data !== null){
            Object.keys(data).map((key) => {
                formData.append(key,data[key]);
            });
        }

        return fetch(url, {
			method: 'POST',
			body: formData
		})
        .then((response) => {
            console.log('useHttpPostJSON.js useCallback() response returned');
            console.log('useHttpPostJSON.js useCallback() response.status: '+response.status);
            console.log('useHttpPostJSON.js useCallback() response.type: '+response.type);
            return response.json()
        })
		.then(jsonObj => {
            console.log('useHttpPostJSON.js useCallback() jsonObj returned');
            console.log('useHttpPostJSON.js useCallback() typeof callback: '+typeof callback);

            if (typeof callback === "function") {
                console.log('useHttpPostJSON.js useCallback() use callback function');
                let [returnStatus, returnValue] = callback(jsonObj);
                console.log('useHttpPostJSON.js useCallback() callback returnStatus: '+returnStatus);
                console.log('useHttpPostJSON.js useCallback() callback returnValue: '+JSON.stringify(returnValue));
                setValue(returnValue);
                setStatus(returnStatus);
            }
            else{
                console.log('useHttpPostJSON.js useCallback() no callback function');
                console.log('useHttpPostJSON.js useCallback() jsonObj: '+JSON.stringify(jsonObj));
                setValue(jsonObj);
                setStatus("success");
            }
        })
        .catch((error) => {
            console.log('useHttpPostJSON.js useCallback() fetch error: '+error);
            setError(error);
            setStatus("error");
        });
    }, [url, data, callback]);

    useEffect(() => {
        console.log('useHttpPostJSON useEffect() [execute, readyToUpdate] invoked');
        if(readyToUpdate){
            console.log('useHttpPostJSON useEffect() [execute, readyToUpdate] execute immediately');
            execute();
            setReadyToUpdate(false);
        }
    },[execute, readyToUpdate, setReadyToUpdate]);

    const refresh = () => {
        setReadyToUpdate(true);
    }

    return [ value, refresh, status, error, setUrl, setData, setCallback ];
};    
