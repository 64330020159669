import React from 'react';
import DropdownMenu from './views/DropdownMenu';

function Menu(props){
	return (
        <DropdownMenu {...props} />
	);
}
Menu.Item = DropdownMenu.Item;

export default Menu;