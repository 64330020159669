import React from 'react';
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'

function PointsToaster(props) {
    return(
        <ToastContainer position='top-center' className='d-flex flex-column justify-content-center pt-3'>
            {props.toasts.map((toast, index) => { 
                return (
                    <Toast className='border-0 shadow-none' style={{backgroundColor:'transparent'}}>
                        <Toast.Header className={toast.className+' border border-3 rounded-3vmin'} closeButton={false}>
                        <strong className="fs-1 fs-bold mx-auto">{toast.text}</strong>
                        </Toast.Header>
                    </Toast>
                );
            })}
        </ToastContainer>
    );
}

export default PointsToaster;